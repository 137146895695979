import styled from "styled-components";
import bgimg from "../../assets/img/bg1.png";

export const Wrapper = styled.section`
  position: relative;
  background: #e5ddd5;
`;

export const Screen = styled.section`
  background-image: url(${bgimg});
  color: #303030;
`;
