import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Sobre from "./pages/Sobre";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./style.scss";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: "#303030",
      main: "#303030",
      dark: "#303030",
      contrastText: "#fff"
    }
  }
});

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            
            <Route
              path="/loja/:loja/:post"
              render={props => <Home {...props} />}
            />
            <Route path="/sobre" render={props => <Sobre {...props} />} />
            <Route path="/loja/:loja" render={props => <Home {...props} />} />
            <Route path="/:post" render={props => <Home {...props} />} />
            <Route path="/" render={props => <Home {...props} />} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
