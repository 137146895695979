import React from "react";
// import Level1 from "./Level1";
// import { useSelector } from "react-redux";
import postCtrl from "../../../../controllers/post";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { Wrapper, Close } from "./style";
import { useSelector } from "react-redux";

export default function Header() {
  const level1 = useSelector(s => s.level1);

  function removeLevel1() {
    postCtrl.setLevel1();
  }

  return (
    <Wrapper>
      {level1 && (
        <Close>
          <div onClick={() => removeLevel1()}>
            <ClearIcon />
          </div>
        </Close>
      )}

      <Typography
        variant="caption"
        color="textSecondary"
        component="p"
        style={{ color: "white" }}
      >
        Categorias
      </Typography>
    </Wrapper>
  );
}
