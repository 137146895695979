import React, { useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import postCtrl from "../../../controllers/post";

const useStyles = makeStyles(theme => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  }
}));

let timeout = null;

export default function BluInputBase() {
  const textInput = createRef();
  const classes = useStyles();
  const storeSearch = useSelector(s => s.search); // valor que está no input
  // const [search, setSearch] = useState(""); // valor que está no input
  const loading = useSelector(s => s.loading);
  const level1 = useSelector(s => s.level1);
  const level2 = useSelector(s => s.level2);
  const level3 = useSelector(s => s.level3);

  useEffect(() => {
    if (storeSearch === "" || storeSearch === undefined) {
      textInput.current.children[0].value = "";
    }
  }, [storeSearch, textInput]);

  const handleChange = event => {
    const txt = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      postCtrl.setSearch(txt);
    }, 1000);
  };

  function keyPressed(evt) {
    if (evt.key === "Enter") {
      console.log("NUMERO 4....");
      postCtrl.setSearch(textInput.current.children[0].value);
    }
  }

  function onClick() {
    postCtrl.setSearch(textInput.current.children[0].value);
  }

  function getPlaceholder() {
    if (!level1) {
      return "Pesquisa Bluclassi...";
    }

    let placeholder = "Pesquisa " + level1.name;

    if (level2) {
      placeholder += !level2.hide ? " / " + level2.name : "";
    }
    if (level3) {
      placeholder += " / " + level3.name;
    }
    placeholder += "...";
    return placeholder;
  }

  return (
    <>
      <InputBase
        ref={textInput}
        className={classes.input}
        placeholder={getPlaceholder()}
        // value={search}
        onChange={handleChange}
        onKeyPress={keyPressed}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={onClick}
      >
        {loading ? <AutorenewOutlinedIcon /> : <ArrowForward />}
      </IconButton>
    </>
  );
}
