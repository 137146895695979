import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ctrlPost from "../../../controllers/post";
import SendIcon from "@material-ui/icons/Send";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1)
  },
  paper: {
    maxWidth: "450px",
    background: "#FFFFE8",
    padding: "10px"
  },
  tableLabel: {
    textAlign: "right",
    fontSize: "0.8em",
    fontWeight: "normal",
    opacity: 0.7,
    textTransform: "firstupper"
  }
}));

export default function() {
  const newPost = useSelector(s => s.newPost);
  const step = useSelector(s => s.step);
  const classes = useStyles();

  const corrigir = step => {
    ctrlPost.setStep(step);
  };

  const handleReset = () => {
    ctrlPost.setStep(0);
  };

  const handleSubmit = async () => {
    const ok = await ctrlPost.submitNewPost();
    if (ok) {
      ctrlPost.setStep(step + 1);
    }
  };

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <th className={classes.tableLabel}>Título</th>
              <td>
                <Typography
                  variant="h6"
                  component="h2"
                  onClick={() => corrigir(2)}
                >
                  {newPost.title}
                </Typography>
              </td>
            </tr>
            <tr>
              <th className={classes.tableLabel}>Categoria</th>
              <td>
                <Typography
                  onClick={() => corrigir(1)}
                  style={{ fontSize: "0.9em", fontWeight: "bold" }}
                >
                  {newPost.level1 && newPost.level1.name} /{" "}
                  {newPost.level2 && !newPost.level2.hide && (
                    <span>{newPost.level2 && newPost.level2.name} / </span>
                  )}
                  {newPost.level3 && newPost.level3.name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th className={classes.tableLabel}>Texto</th>
              <td>
                <Typography variant="body1" onClick={() => corrigir(2)}>
                  {newPost.description}
                </Typography>
              </td>
            </tr>
            <tr>
              <th className={classes.tableLabel}>Anunciante</th>
              <td>
                <Typography
                  variant="body1"
                  component="p"
                  onClick={() => corrigir(0)}
                >
                  {newPost.seller.name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th className={classes.tableLabel}>Whatsapp</th>
              <td>
                <Typography
                  variant="body1"
                  component="p"
                  onClick={() => corrigir(0)}
                >
                  {newPost.seller.phone}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <div>
        <Button onClick={handleReset} className={classes.button}>
          Corrigir
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className={classes.button}
          endIcon={<SendIcon />}
        >
          ENVIAR PARA ANÁLISE
        </Button>
      </div>
    </>
  );
}
