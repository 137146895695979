import React, { createRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Wrapper, Container, InnerContainer } from "./style";
import Card from "./PostCard";
import Menu from "./PostMenu";
import Modal from "./PostModal";

import Button from "@material-ui/core/Button";

export default function Posts(props) {
  const srvPost = useSelector(s => s.srvPost);
  const hasMore = useSelector(s => s.srvPost.hasMore);
  const posts = useSelector(s => s.posts);
  const scroll = useSelector(s => s.navigationScroll);
  const back = useSelector(s => s.navigationBack);
  const loja = useSelector(s => s.loja);
  const dispatch = useDispatch();
  const refContainer = createRef();

  useEffect(() => {
    if (back) {
      refContainer.current.scrollLeft = scroll;
      // navCtrl.setBack(false);
    }
  }, [refContainer, scroll, back]);

  function more() {
    srvPost.getMore(posts => {
      dispatch({ type: "SET_POSTS", posts });
    });
  }

  return (
    <Wrapper>
      <Container ref={refContainer}>
        <InnerContainer>
          {!loja && <Menu />}
          {posts.map(post => {
            return <Card key={post.id} post={post} container={refContainer} />;
          })}
          <BtnMostrarMais more={more} hasMore={hasMore} />
          <Modal />
        </InnerContainer>
      </Container>
    </Wrapper>
  );
}

function BtnMostrarMais({ more, hasMore }) {
  if (hasMore) {
    return (
      <Button
        onClick={more}
        variant="contained"
        color="primary"
        style={{ margin: 4 }}
      >
        Ver mais
      </Button>
    );
  }
  return "";
}
