import React from "react";
import { useSelector } from "react-redux";
import { Wrapper, Menu } from "./style";
import { Link } from 'react-router-dom'
import Typography from "@material-ui/core/Typography";

// import InstagramIcon from "@material-ui/icons/Instagram";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";

// import BtnLink from "../BtnLink";

import Button from "@material-ui/core/Button";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { Link } from 'react-router-dom';

export default function Classi() {
  const loja = useSelector(s => s.loja);
  return (
    <Wrapper>
      <Link to="/">
        <img src={require("../../assets/img/logo-bg-top.svg")} alt="Logo" />
      </Link>
      {loja && (
        <a href={"/loja/" + loja.id}>
          <img
            src={require("../../assets/loja/" + loja.id + ".png")}
            alt="Logo"
          />
        </a>
      )}
      {!loja && (
        <Menu>
          {/* <Link to="/sobre">
            <Typography>Sobre</Typography>
          </Link> */}
          {/* <BtnLink text="Whatsapp" icon={true} link={'https://api.whatsapp.com/send?phone=554792248557&text=Ol%C3%A1!%20Cheguei%20aqui%20atrav%C3%A9s%20do%20site%20BluClassi.'} /> */}

          <Link to="/sobre" style={{color:"#333", textDecoration:"none", marginRight:"5px"}}>
            <Typography variant="button">Sobre</Typography>
          </Link>

          <Button target="_blank" href={'https://api.whatsapp.com/send?phone=554792248557&text=Ol%C3%A1!%20Cheguei%20aqui%20atrav%C3%A9s%20do%20site%20BluClassi.'}>
            <FontAwesomeIcon icon={faWhatsapp} />
            Contato
          </Button>

          {/* <BtnLink text="Sobre" icon={false} link={'/sobre'} /> */}
          {/* <a href="https://www.instagram.com/bluclassi" target="_blank"><InstagramIcon /></a>
          <a href="https://www.facebook.com/bluclassi" target="_blank"><FacebookIcon /></a>
          <a href="https://api.whatsapp.com/send?phone=554792248557&text=Ol%C3%A1!%20Cheguei%20aqui%20atrav%C3%A9s%20do%20site%20BluClassi." target="_blank"><WhatsAppIcon /></a> */}
        </Menu>
      )}
    </Wrapper>
  );
}
