import styled from "styled-components";

export const Wrapper = styled.section`
  /* border: 5px solid green; */
  /* background: rgba(255, 255, 0, 0.3); */
  position: relative;
`;

export const Container = styled.div`
  /* border: 5px solid green; */
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

export const InnerContainer = styled.div`
  /* border: 5px solid green; */
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 0;
`;
