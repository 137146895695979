import styled from "styled-components";

export const Wrapper = styled.section`
  margin: 3px;
  width: 194px;
  h2,
  p {
    line-height: 1.2em !important;
  }
  .alert {
    color: blue;
  }
`;
