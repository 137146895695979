import styled from "styled-components";

export const Wrapper = styled.section`
  /* border: 1px solid black; */
`;

export const Botao = styled.div`
  color: white;
  text-transform: uppercase;
  padding: 8px 10px 3px 10px;
  :hover {
    background-color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
  }
`;
