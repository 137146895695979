import React from "react";
import { Wrapper } from "./style";
import Buttons from "./Buttons";
// import Select from "./Select";
// import { useSelector } from "react-redux";

export default function Level2() {
  // const level2 = useSelector(s => s.level2);

  return (
    <Wrapper>
      <Buttons />
      {/* {level2 && <Select />} */}
    </Wrapper>
  );
}
