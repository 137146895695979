import React from "react";
// import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Input from "./Input";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import Menu from "./Menu";
// import React from "react";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import postCtrl from "../../controllers/post";

import { Wrapper } from "./style";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 502,
    margin: "0 auto"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function CustomizedInputBase() {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);

  // const levels = useSelector(s => s.levels);

  function submitHandler(e) {
    e.preventDefault();
  }

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const setLevel1 = l1 => {
  //   closeMenu();
  //   postCtrl.setLevel1(l1);
  // };

  // const closeMenu = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Wrapper>
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {levels.map(l1 => {
          return (
            <MenuItem key={l1.id} onClick={() => setLevel1(l1)}>
              {l1.name}
            </MenuItem>
          );
        })}
      </Menu> */}
      <Paper component="form" onSubmit={submitHandler} className={classes.root}>
        {/* <IconButton
          onClick={handleClick}
          className={classes.iconButton}
          aria-label="search"
        >
          <MenuIcon />
        </IconButton> */}
        <Input />
      </Paper>
    </Wrapper>
  );
}
