import React from "react";
import { Wrapper } from "./style";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import postCtrl from "../../../controllers/post";

export default function Classi() {
  const open = () => {
    postCtrl.openNewPost();
  };

  return (
    <Wrapper>
      <Fab color="primary" onClick={open}>
        <AddIcon />
      </Fab>
    </Wrapper>
  );
}
