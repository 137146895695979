import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";

import { Wrapper } from "./style";
// import { green } from "@material-ui/core/colors";

// const images = [
//   {
//     original: "https://picsum.photos/id/1018/1000/600/",
//   },
//   {
//     original: "https://picsum.photos/id/1015/1000/600/",
//   },
//   {
//     original: "https://picsum.photos/id/1019/1000/600/",
//   }
// ];

export default function PostPhoto(props) {
  const post = useSelector(s => s.post);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (post) {
      let images = post.photos.map(p => {
        return { original: p.link };
      });
      setImages(images);
    } else {
      setImages([]);
    }
  }, [post, setImages]);

  return (
    <Wrapper>
      <ImageGallery
        items={images}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    </Wrapper>
  );
}
