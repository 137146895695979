import React from "react";
import Typography from "@material-ui/core/Typography";
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import postCtrl from "../../../../controllers/post";

export default function Footer() {
  const search = useSelector(s => s.search);

  const limpar = () => {
    postCtrl.setSearch();
  };

  return (
    <>
      {search && (
        <Wrapper>
          <Typography variant="caption" color="textSecondary" component="p">
            Filtrando por <b>{search}</b>. <span onClick={limpar}>Limpar</span>
          </Typography>
        </Wrapper>
      )}
    </>
  );
}
