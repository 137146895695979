import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ctrlPost from "../../../controllers/post";

export default function() {
  const newPost = useSelector(s => s.newPost);
  const newPostErrors = useSelector(s => s.newPostErrors);

  const hasError = name => {
    return newPostErrors.find(e => e.key === name) ? true : false;
  };
  
  const handleChange = prop => e => {
    ctrlPost.setNewPost({ ...newPost, [prop]: e.target.value });
  };


  return (
    <Grid container spacing={1} alignItems="flex-start" direction="column">
      <Grid item>
        <TextField
          error={hasError("postTitle")}
          variant="filled"
          defaultValue={newPost.title}
          onChange={handleChange("title")}
          label="Título do anúncio"
          style={{ width: "265px" }}
          inputProps={{
            maxLength: 30
          }}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <TextField
            error={hasError("postDescription")}
            variant="filled"
            label="Texto do anúncio"
            defaultValue={newPost.description}
            onChange={handleChange("description")}
            multiline
            rows="11"
            style={{ width: "265px" }}
            inputProps={{
              maxLength: 255
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
