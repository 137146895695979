import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import ctrlPost from "../../../controllers/post";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1)
  },
  card: {
    maxWidth: "450px"
  }
}));

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Step1 step={step} />;
    case 1:
      return <Step2 step={step} />;
    case 2:
      return <Step3 step={step} />;
    case 3:
      return <Step4 step={step} />;
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const steps = useSelector(s => s.steps);
  const step = useSelector(s => s.step);
  const errors = useSelector(s => s.newPostErrors);

  const handleNext = () => {
    ctrlPost.setStep(step + 1);
  };

  const handleBack = () => {
    ctrlPost.setStep(step - 1);
  };

  const handleExit = () => {
    ctrlPost.closeNewPost();
    handleNew();
  };

  const handleNew = () => {
    ctrlPost.clearNewPost();
    ctrlPost.setStep(0);
  };

  const setStep = step => {
    ctrlPost.setStep(step);
  };

  const hasError = (fields, index) => {
    return errors.find(e => fields.find(f => f === e.key)) ? true : false;
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={step} orientation="vertical">
        {steps.map((s, index) => (
          <Step key={s.label}>
            <StepLabel
              error={hasError(s.fields, index)}
              onClick={() => setStep(index)}
            >
              {s.label}
            </StepLabel>
            <StepContent>
              <Typography component={"span"}>
                {getStepContent(index)}
              </Typography>
              {step !== steps.length - 1 && (
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={step === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Próximo
                    </Button>
                  </div>
                </div>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {step === steps.length && (
        <div style={{ padding: "30px" }}>
          <Typography>Obrigado por enviar seu anúncio!</Typography>
          <div>
            <Button onClick={handleExit} className={classes.button}>
              Voltar
            </Button>
            <Button onClick={handleNew} className={classes.button}>
              Criar outro anúncio
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
