import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
  border-top: 4px solid #303030;
  img {
    height: 40px;
  }
`;

export const Menu = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;


