import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Posts from "../../components/Posts";
import Search from "../../components/Search";
import Header from "../../components/Header";
import Add from "../../components/Add";
// import Debug from "../../components/Debug";
import postCtrl from "../../controllers/post";
import { Wrapper, Screen } from "./style";
import lojas from "./lojas";

export default function Home(props) {
  // se clicou no botão fechar modal de post então é true;

  const initiated = useSelector(s => s.initiated);
  const loja = useSelector(s => s.loja);

  useEffect(() => {
    
    const postId = parseInt(props.match.params.post);
    let lojaId = parseInt(props.match.params.loja);

    function setLoja() {
      // loja
      const subdomain = window.location.href.split("//")[1].split(".")[0];
      if (lojas[subdomain] > 0) {
        lojaId = lojas[subdomain];
      }

      if (lojaId && (!loja || loja.id !== lojaId)) {
        postCtrl.setLoja(lojaId);
      } else if (!lojaId && loja) {
        postCtrl.setLoja();
      }
    }

    function setPost() {
      // post and posts
      if (postId) {
        postCtrl.openById(postId, () => {
          if (!initiated) postCtrl.init();
        });
      } else {
        if (!initiated) postCtrl.init();
      }
    }

    const init = function() {
      setLoja();
      setPost();
    };

    init();

  }, [props, initiated, loja]);

  return (
    <Wrapper>
      <Screen>
        <Header />
        {/* <Debug /> */}
        <Search />
        <Posts />
        <Add />
      </Screen>
      {/* <Footer /> */}
    </Wrapper>
  );
}
