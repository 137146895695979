import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import postCtrl from "../../../../../controllers/post";

export default function SimpleSelect() {
  const [value, setValue] = React.useState("");
  const level1 = useSelector(s => s.level1);
  const level2 = useSelector(s => s.level2);
  const level3 = useSelector(s => s.level3);

  // apenas para fazer o tamanho do label ficar botinho
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    // pega o indice correspondente ao level selecionado
    const index = level1 && level2 && level3 && level2.childs.indexOf(level3);
    console.log("--------->INDEX3::::::", index);
    if (level3) {
      console.log("--------->UPDATE INDICE 3", index);
      setValue(index);
    } else {
      console.log("--------->ZERAR INDICE 3", index);
      setValue("");
    }
  }, [level1, level2, level3]);

  const handleChange = event => {
    // pega o índice do item selecionado
    const index = event.target.value;
    // com base no índice encontra o respectivo objeto da lista
    const l3 = index !== -1 ? level2.childs[index] : null;
    // manda o controller setar o objeto
    postCtrl.setLevel3(l3);
    // faz ficar selecionado o item do select na tela
    setValue(index);
  };

  return (
    <div>
      <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
        <InputLabel ref={inputLabel}>{level1 && level1.nameL3}</InputLabel>
        <Select value={value} onChange={handleChange} labelWidth={labelWidth}>
          <MenuItem value="">
            <em>Todos</em>
          </MenuItem>
          {level2 &&
            level2.childs.map((l3, index) => {
              return (
                <MenuItem key={l3.id} value={index}>
                  {l3.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
