import React, { useState } from "react";
import { useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import PhotoIcon from "@material-ui/icons/PhotoCamera";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCoffee } from "@fortawesome/free-solid-svg-icons";

import postCtrl from "../../../controllers/post";
import navCtrl from "../../../controllers/navigation";

import { Wrapper } from "./style";
// import { green } from "@material-ui/core/colors";

export default function PostCard(props) {
  const [post] = useState(props.post);
  const container = props.container;
  const history = useHistory();
  const loja = useSelector(s => s.loja);

  function openPost() {
    // postCtrl.open(post);
    // console.log("container.current.scrollLeft", container.current.scrollLeft);
    const link = (loja ? "/loja/" + loja.id : "") + post.link;
    navCtrl.pushWithScroll(container.current.scrollLeft, () => {
      history.push(link);
      postCtrl.open(post);
    });
  }

  function getDescription() {
    const MAX_SIZE = 80;
    return post.description.length > MAX_SIZE
      ? post.description.substring(0, MAX_SIZE) + "..."
      : post.description;
  }

  return (
    <Wrapper>
      <Card variant="outlined">
        <CardActionArea onClick={openPost}>
          <CardContent style={{ padding: 10 }}>
            {post.photos.length > 0 && (
              <img
                src={post.photos[0].mini}
                alt="img"
                style={{ width: "100%", marginBottom: "5px" }}
              />
            )}
            <Typography
              gutterBottom
              variant="subtitle1"
              component="h2"
              style={{ marginTop: 0, fontWeight: "bold", position: "relative" }}
            >
              {post.photos.length > 0 && (
                <PhotoIcon style={{ fontSize: "18px", float: "right" }} />
              )}{" "}
              {post.title}
            </Typography>
            {!post.photos.length && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ color: "#444", marginBottom: "4px" }}
              >
                {getDescription()}
              </Typography>
            )}
            <Breadcrumbs
              style={{ color: "#777" }}
              level1={post.level1}
              level2={post.level2}
              level3={post.level3}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Wrapper>
  );

  function Breadcrumbs({ level1, level2, level3 }) {
    return (
      <section style={{ whiteSpace: "nowrap", marginBottom: 5 }}>
        <div style={{ overflow: "hidden", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              background: "rgba(255, 255, 255, 0.5)",
              width: 10,
              height: 17,
              right: 0
            }}
          ></div>
          <Typography variant="caption" style={{ margin: 0 }}>
            {level1.name}
          </Typography>
          {" / "}
          {!level2.hide && (
            <Typography variant="caption">{level2.name}</Typography>
          )}
          {!level2.hide ? " / " : ""}
          <Typography variant="caption">{level3.name}</Typography>
        </div>
      </section>
    );
  }
}
