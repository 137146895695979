import React from "react";
import { useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import postCtrl from "../../../controllers/post";

export default function NativeSelects() {
  const levels = useSelector(s => s.levels);
  const newPost = useSelector(s => s.newPost);
  const level1 = useSelector(s => s.newPost.level1);
  const level2 = useSelector(s => s.newPost.level2);
  const level3 = useSelector(s => s.newPost.level3);
  const newPostErrors = useSelector(s => s.newPostErrors);

  const hasError = name => {
    return newPostErrors.find(e => e.key === name) ? true : false;
  };

  const handleChange = field => e => {
    const id = parseInt(e.target.value);
    let level = null;
    switch (field) {
      case "level1":
        // pega o objeto correspondente ao id
        level = levels.find(l => l.id === id);

        // se for o mesmo ignora
        if (level1 && level1.id === level.id) return;

        // se level1 tem apenas 1 child então já seleciona sem perguntar, senão limpa
        newPost.level2 = level.childs.length === 1 ? level.childs[0] : null;

        // limpa level3
        newPost.level3 = null;
        break;

      case "level2":
        // pega o objeto correspondente ao id
        level = newPost.level1.childs.find(l => l.id === id);

        // se for o mesmo ignora
        if (level2 && level2.id === level.id) return;

        // limpa level3
        newPost.level3 = null;
        break;

      case "level3":
        // pega o objeto correspondente ao id
        level = newPost.level2.childs.find(l => l.id === id);
        break;

      default:
        console.log("do tipo ?");
        break;
    }

    // seta o respetivo level como o level selecionado
    newPost[field] = level;

    // manda avisar a geral que mudou
    postCtrl.setNewPost(newPost);
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" direction="row">
      <Grid item>
        <FormControl
          error={hasError("postLevel1")}
          variant="filled"
          style={{ minWidth: "120px" }}
        >
          <InputLabel>Categoria</InputLabel>
          <Select
            value={level1 ? level1.id : ""}
            onChange={handleChange("level1")}
          >
            {levels &&
              levels.map((l1, index) => {
                return (
                  <MenuItem key={l1.id} value={l1.id}>
                    {l1.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        {level1 && (!level2 || !level2.hide) && (
          <FormControl
            error={hasError("postLevel2")}
            variant="filled"
            style={{ minWidth: "120px" }}
          >
            <InputLabel>Tipo</InputLabel>
            <Select
              value={level2 ? level2.id : ""}
              onChange={handleChange("level2")}
            >
              {level1.childs.map((l2, index) => {
                return (
                  <MenuItem key={l2.id} value={l2.id}>
                    {l2.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item>
        {level1 && level2 && (
          <FormControl
            error={hasError("postLevel3")}
            variant="filled"
            style={{ minWidth: "120px" }}
          >
            <InputLabel>{level1.nameL3}</InputLabel>
            <Select
              value={level3 ? level3.id : ""}
              onChange={handleChange("level3")}
            >
              {level2.childs.map((l3, index) => {
                return (
                  <MenuItem key={l3.id} value={l3.id}>
                    {l3.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}
