import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import BtnWhats from "./BtnWhats";
import CloseIcon from "@material-ui/icons/Close";
import { CloseButton } from "./mui";
import { useHistory } from "react-router-dom";
import PostPhoto from "../PostPhoto";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { Wrapper } from "./style";
import postCtrl from "../../../controllers/post";
import navCtrl from "../../../controllers/navigation";

export default function PostModal() {
  const [open, setOpen] = useState(false);
  const post = useSelector(s => s.post);
  const history = useHistory();
  const loja = useSelector(s => s.loja);
  // const dispatch = useDispatch();

  const handleClose = () => {
    // setOpen(false);
    const link = loja ? "/loja/" + loja.id : "/";
    navCtrl.goBackWithScroll(() => {
      history.push(link);
      postCtrl.close();
    });
  };

  useEffect(() => {
    // console.log("PostModal() useEffect...");
    post ? setOpen(true) : setOpen(false);
  }, [setOpen, post]);

  return (
    <Wrapper>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
          <div style={{ marginRight: 30 }}>
            {post ? post.title : "Anúncio..."}
          </div>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">
              {post && post.level1.name}
            </Typography>
            {post && !post.level2.hide && (
              <Typography color="textPrimary">
                {post && post.level2.name}
              </Typography>
            )}
            <Typography color="textPrimary">
              {post && post.level3.name}
            </Typography>
          </Breadcrumbs>
          <CloseButton onClick={handleClose}>
            <CloseIcon style={{ fontSize: 30 }} />
          </CloseButton>
        </DialogTitle>
        <DialogContent style={{ padding: "0" }}>
          {post && post.photos.length > 0 && <PostPhoto />}
          <section  style={{ padding: "24px" }}>
            <Typography variant="subtitle1">
              {post && post.description}
            </Typography>
            <div style={{ display: "flex", marginTop: 20 }}>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="overline"
                  display="block"
                  gutterBottom
                  style={{ margin: 0 }}
                >
                  anunciante
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                  {post && post.seller.name}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="overline"
                  display="block"
                  gutterBottom
                  style={{ margin: 0 }}
                >
                  cadastrado por
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                  {post && post.company.name}
                </Typography>
              </div>
            </div>
          </section>
        </DialogContent>
        <DialogActions>{post && <BtnWhats link={post.whats} />}</DialogActions>
      </Dialog>
    </Wrapper>
  );
}
