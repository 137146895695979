import React from "react";
import { useHistory } from "react-router-dom";
import navCtrl from "../../controllers/navigation";
import Header from "../../components/Header";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Wrapper, Screen, InnerWrapper, Sociais } from "./style";
import { Container } from "@material-ui/core";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export default function Sobre() {
  let history = useHistory();

  function voltar() {
    navCtrl.goBackWithScroll(() => {
      history.goBack();
    });
  }

  return (
    <Wrapper>
      <Screen>
        <Header />

        <Container>
          <InnerWrapper>
            <br />
            <br />
            <Typography variant={'h4'}>Sobre o BluClassi</Typography>
            <br />
            <Typography>
              O Classificados de Blumenau e região, do jeito antigo, sem propagandas chatas e direto ao ponto. Encontre o que procura rapidamente e inicie uma conversa por WhatsApp, com um clique direto com o anunciante.
            </Typography>


            <Sociais>
                <Typography><InstagramIcon />bluclassi</Typography>
                <Typography><FacebookIcon />bluclassi</Typography>
                <Typography><WhatsAppIcon />(47)9224-8557</Typography>
            </Sociais>
            <br />
            <Button type="button" onClick={voltar}>
              Voltar
            </Button>
          </InnerWrapper>
        </Container>
      </Screen>
    </Wrapper >
  );
}
