import styled from "styled-components";
import close from "../../../../assets/img/menu-close.svg";

export const Wrapper = styled.section`
  /* border: 1px solid black; */
  background: #303030;
  padding: 5px 0 2px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
`;

export const Close = styled.div`
  /* border: 1px solid black; */
  z-index: 1;
  position: absolute;
  top: -0.5px;
  right: -0.5px;
  /* background-color:red; */
  background-image: url(${close});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto 27.5px;
  width: 52px;
  height: 34px;
  text-align: right;
  div {
    cursor: pointer;
    /* background: green; */
    padding-right: 5px;
    width: 30px;
    margin-left: auto;
  }
`;
