import React from "react";
import { Wrapper } from "./style";
import Select from "./Select";

export default function Level3() {
  return (
    <Wrapper>
      <Select />
    </Wrapper>
  );
}
