import React from "react";
// import { Wrapper } from "./style";
import { WhatsButton } from "./mui";

// import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BtnWhats({ link }) {
  return (
    <>
      <WhatsButton target="_blank" href={link}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x"/> 
        &nbsp;Contato pelo Whatsapp
      </WhatsButton>
    </>
  );
}
