import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Stepper from "../Stepper";
import srvPost from "../../../controllers/post";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function() {
  const classes = useStyles();
  const openNewPost = useSelector(s => s.openNewPost);
  const newPostAlert = useSelector(s => s.newPostAlert);
  const steps = useSelector(s => s.steps);
  // const newPostErrors = useSelector(s => s.newPostErrors);

  const handleClose = () => {
    srvPost.closeNewPost();
    srvPost.setStep(0);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openNewPost}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Envie sua publicação
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              sair
            </Button>
          </Toolbar>
        </AppBar>
        {newPostAlert && (
          <Alert severity="error">
            <AlertTitle>Atenção:</AlertTitle>
            {newPostAlert}
          </Alert>
        )}
        {/* {newPostErrors.map(error => {
          return (
            <p key={error.key}>
              {error.key}: {error.message}
            </p>
          );
        })} */}
        <div style={{ padding: "30px 0 0 30px" }}>
          <Typography>
            Publicar no Bluclassi é muito fácil! Siga os {steps.length} passos
            abaixo:
          </Typography>
        </div>
        <Stepper />
      </Dialog>
    </div>
  );
}
