import React from "react";
import { Wrapper } from "./style";
import Buttons from "./Buttons";

export default function Level1() {
  return (
    <Wrapper>
      <Buttons />
    </Wrapper>
  );
}
