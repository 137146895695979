import { createStore } from "redux";
import Api from "./services/Api";

const INITIAL_STATE = {
  // filter
  initiated: false, // true se já rodou o init do post controller
  search: "",
  cleanSearch: 0,
  // loading
  loading: false,
  // loja
  loja: null,
  srvLoja: new Api("loja"),
  // post
  post: null,
  srvPost: new Api("post"),
  posts: [],
  // level
  level1: null,
  level2: null,
  level3: null,
  srvLevel: new Api("level"),
  levels: [],
  // new post
  openNewPost: false,
  newPost: {
    title: "",
    description: "",
    level1: null,
    level2: null,
    level3: null,
    seller: {
      name: "",
      phone: ""
    }
  },
  steps: [
    {
      label: "Nome e número do Whatsapp",
      fields: ["sellerName", "sellerPhone"]
    },
    {
      label: "Selecione a categoria",
      fields: ["postLevel1", "postLevel2", "postLevel3"]
    },
    {
      label: "Título e conteúdo do anúncio",
      fields: ["postTitle", "postDescription"]
    },
    {
      label: "Confira os dados e envie para análise",
      fields: []
    }
  ],
  step: 0,
  newPostAlert: null,
  newPostErrors: [],
  navigationScroll: 0
};

const store = createStore((state = INITIAL_STATE, action) => {
  switch (action.type) {
    // filter
    case "SET_SEARCH":
      console.log("SET_SEARCH", action.search);
      return { ...state, search: action.search };
    case "REMOVE_SEARCH":
      console.log("REMOVE_SEARCH");
      return { ...state, cleanSearch: state.cleanSearch + 1 };
    // loading
    case "SET_LOADING":
      console.log("SET_LOADING", action.loading);
      return { ...state, loading: action.loading };
    // loja
    case "SET_LOJA":
      console.log("SET_LOJA", action.loja);
      return { ...state, loja: action.loja };
    // posts
    case "SET_POSTS":
      console.log("SET_POSTS", action.posts);
      return { ...state, posts: action.posts };
    case "SET_POST":
      console.log("SET_POST", action.post);
      return { ...state, post: action.post };
    // level
    case "SET_LEVELS":
      console.log("SET_LEVELS", action.levels);
      return { ...state, levels: action.levels };
    case "SET_LEVEL1":
      console.log("SET_LEVEL1", action.level1);
      return { ...state, level1: action.level1 };
    case "SET_LEVEL2":
      console.log("SET_LEVEL2", action.level2);
      return { ...state, level2: action.level2 };
    case "SET_LEVEL3":
      console.log("SET_LEVEL3", action.level3);
      return { ...state, level3: action.level3 };
    // new post
    case "SET_OPEN_NEW_POST":
      console.log("SET_OPEN_NEW_POST", action.openNewPost);
      return { ...state, openNewPost: action.openNewPost };
    case "SET_NEW_POST":
      console.log("SET_NEW_POST", action.newPost);
      return { ...state, newPost: action.newPost };
    case "SET_STEP":
      console.log("SET_NEW_POST", action.step);
      return { ...state, step: action.step };
    case "SET_NEW_POST_ALERT":
      console.log("SET_NEW_POST_ALERT", action.alert);
      return { ...state, newPostAlert: action.alert };
    case "SET_NEW_POST_ERRORS":
      console.log("SET_NEW_POST_ERRORS", action.errors);
      return { ...state, newPostErrors: action.errors };
    // navigation
    case "SET_INITIATED":
      console.log("SET_INITIATED", action.initiated);
      return { ...state, initiated: action.initiated };
    case "SET_NAVIGATION_SCROLL":
      console.log("SET_NAVIGATION_SCROLL", action.navigationScroll);
      return { ...state, navigationScroll: action.navigationScroll };
    default:
      return state;
  }
});

export default store;
