import store from "../store";

const srvLoja = store.getState().srvLoja;
const srvPost = store.getState().srvPost;
const srvLevel = store.getState().srvLevel;

const KEY_NEW_POST = "newpost";

class PostController {
  init() {
    // informa que já foi pedido para iniciar
    store.dispatch({ type: "SET_INITIATED", initiated: true });
    // console.log("PostController.init()");
    this._setLoading(true);
    // carrega todos os níveis
    srvLevel.getAll(levels => {
      store.dispatch({ type: "SET_LEVELS", levels });
    });
    // carrega primeiros posts
    srvPost.get(posts => {
      store.dispatch({ type: "SET_POSTS", posts });
      this._setLoading(false);
    });
    // verificar se tem new post rascunho no localStorage, em caso positivo atualiza o do Store.
    this.initNewPost();
  }

  // verifica se já foi inicializado, se já chamou init();
  initiated() {
    return store.getState().levels && store.getState().levels.length;
  }

  initNewPost() {
    // se tiver post no localStorage, coloca ele no store
    let post = localStorage.getItem(KEY_NEW_POST);
    if (post !== null) {
      store.dispatch({ type: "SET_NEW_POST", newPost: JSON.parse(post) });
    }
  }

  openNewPost() {
    store.dispatch({ type: "SET_OPEN_NEW_POST", openNewPost: true });
  }

  closeNewPost() {
    store.dispatch({ type: "SET_OPEN_NEW_POST", openNewPost: false });
  }

  setNewPost(value) {
    console.log(value);
    // atualiza no localStorage
    localStorage.setItem(KEY_NEW_POST, JSON.stringify(value));
    // atualiza no store
    store.dispatch({ type: "SET_NEW_POST", newPost: value });
  }

  clearNewPost() {
    // reset new post
    const newPost = store.getState().newPost;
    newPost.title = "";
    newPost.description = "";
    newPost.level1 = null;
    newPost.level2 = null;
    newPost.level3 = null;
    this.setNewPost(newPost);
  }

  setStep(value) {
    store.dispatch({ type: "SET_STEP", step: value });
  }

  async submitNewPost() {
    // apaga erros
    store.dispatch({ type: "SET_NEW_POST_ALERT" });
    store.dispatch({ type: "SET_NEW_POST_ERRORS", errors: [] });

    // prepara axios
    const newPost = store.getState().newPost;
    const axios = require("axios-jsonp-pro");
    const req = axios.create({
      baseURL: "https://conta.bluclassi.com.br/apix/post/create",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    // submit
    const { data } = await req.post(
      "https://conta.bluclassi.com.br/apix/post/create",
      newPost
    );

    // trata resposta
    if (data.classe === "Eliti_Response_Error_Validation") {
      // set alert
      store.dispatch({
        type: "SET_NEW_POST_ALERT",
        alert: "Corrija os campos destacados"
      });
      // set errors
      let errors = [];
      console.warn(data.data);
      for (const [key, value] of Object.entries(data.data)) {
        console.log(key, value);
        errors.push(value);
      }
      store.dispatch({ type: "SET_NEW_POST_ERRORS", errors });
      // exibe o primeiro step com erro
      const steps = store.getState().steps;
      const stepsFields = steps.map(s => s.fields);
      const errorKeys = errors.map(e => e.key);
      let indexStep = false;
      for (const [i, fields] of Object.entries(stepsFields)) {
        const found = fields.some(r => errorKeys.includes(r));
        if (found && indexStep === false) {
          indexStep = parseInt(i);
        }
      }
      if (indexStep !== false) {
        this.setStep(indexStep);
      }

      return false;
    }
    return true;
  }

  setSearch(search) {
    /*
     Aqui é muito importante que o _setLoading fique depois do SET_SEARCH, nunca antes
     Caso contrário, o useEffect o input do Search vou limpar o valor na hora errada
     */
    // console.warn("CONTROLLER setSearch", search);
    search = search === undefined ? "" : search;
    store.dispatch({ type: "SET_SEARCH", search });
    this._setLoading(true);
    srvPost.filter = search;
    srvPost.search(posts => {
      store.dispatch({ type: "SET_POSTS", posts });
      this._setLoading(false);
    });
  }

  setName() {
    store.dispatch({ type: "SET_NAME", name: "João" });
  }

  open(post) {
    store.dispatch({ type: "SET_POST", post });
  }

  close() {
    store.dispatch({ type: "SET_POST" });
  }

  openById(id, callback) {
    // carrega primeiros posts
    srvPost.getById(id, post => {
      this.open(post);
      callback();
    });
  }

  setLevel1(level1) {
    this._setLoading(true);
    if (level1) {
      this._addLevel1(level1);
    } else {
      this._cleanLevel1();
    }
    srvPost.search(posts => {
      this._setPosts(posts);
      this._setLoading(false);
    });
  }

  setLevel2(level2) {
    this._setLoading(true);
    if (level2) {
      this._addLevel2(level2);
    } else {
      this._cleanLevel2();
    }
    srvPost.search(posts => {
      this._setPosts(posts);
      this._setLoading(false);
    });
  }

  setLevel3(level3) {
    this._setLoading(true);
    if (level3) {
      this._addLevel3(level3);
    } else {
      this._cleanLevel3();
    }
    srvPost.search(posts => {
      this._setPosts(posts);
      this._setLoading(false);
    });
  }

  setLoja(id) {
    if (id) {
      // mandar api filtar por loja
      srvPost.addKey("loja", id, null, false);

      // pegar objeto loja (entity) do backend
      srvLoja.getById(id, loja => {
        // colocar loja no redux
        store.dispatch({ type: "SET_LOJA", loja });
      });
    } else {
      //remove loja
      srvPost.removeKey("loja", null, false);
      store.dispatch({ type: "SET_LOJA" });
    }
  }

  _setLoading(loading) {
    store.dispatch({ type: "SET_LOADING", loading });
  }

  _setPosts(posts) {
    store.dispatch({ type: "SET_POSTS", posts });
  }

  _addLevel1(level1) {
    store.dispatch({ type: "SET_LEVEL1", level1 });
    srvPost.addKey("level1", level1.id, false);
    this._cleanLevel2();
    // tiver apenas um filho
    if (level1.childs.length === 1) {
      // seleciona automaticamente level 2
      const level2 = level1.childs[0];
      this._addLevel2(level2);
    }
  }

  _addLevel2(level2) {
    store.dispatch({ type: "SET_LEVEL2", level2 });
    srvPost.addKey("level2", level2.id, false);
    this._cleanLevel3();
  }

  _addLevel3(level3) {
    store.dispatch({ type: "SET_LEVEL3", level3 });
    srvPost.addKey("level3", level3.id, false);
  }

  _cleanSearch() {
    store.dispatch({ type: "SET_SEARCH" });
    srvPost.removeKey("level1", null, false);
    this._cleanLevel2();
  }

  _cleanLevel1() {
    store.dispatch({ type: "SET_LEVEL1" });
    store.dispatch({ type: "SET_SEARCH" });
    srvPost.filter = "";
    srvPost.removeKey("level1", null, false);
    this._cleanLevel2();
  }

  _cleanLevel2() {
    store.dispatch({ type: "SET_LEVEL2" });
    srvPost.removeKey("level2", null, false);
    this._cleanLevel3();
  }

  _cleanLevel3() {
    store.dispatch({ type: "SET_LEVEL3" });
    srvPost.removeKey("level3", null, false);
  }
}

const postCtrl = new PostController();

export default postCtrl;
