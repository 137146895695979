import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const CloseButton = withStyles({
  root: {
    position: "absolute",
    top: "10px",
    right: "10px",
    float: "right",
    minWidth: "24px"
  }
  // label: {
  //   textTransform: "capitalize",
  //   background: "green",
  //   padding: 0,
  //   margin: 0
  // }
})(Button);
