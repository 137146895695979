import styled from "styled-components";

export const Wrapper = styled.section`
  padding-top: 10px;
  span {
    color: #303030;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
`;
