export default class {
  constructor(entity) {
    this.entity = entity;

    // arquitetura antiga
    this.offset = 0; // controle da página
    this.limit = 50; // quantidade em cada página

    // arquitetura nova
    this.page = 1; // controle da página
    // this.show = 8; // quantidade em cada página
    this.filter = "";
    this._orderBy = "";
    this._orderDesc = false;
    this.keys = [];
    this.hasMore = false;
    this.more = false;
    this.objects = [];
    this.baseUrl = "https://conta.bluclassi.com.br/api/" + this.entity + "/";
    // this.baseUrl = "https://conta.bluclassi.com.br/api/";
    this.loading = false; // quando está buscando objetos

    // Axios Jsonp Pro
    let axios = require("axios-jsonp-pro");
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  }

  search(callback) {
    console.log("BluApi.search()");
    this.page = 1;
    this.offset = 0;
    this.get(callback);
  }

  get(callback) {
    console.log("BluApi.get(callback): ");
    const url = this._getUrl();
    this.loading = true;
    this.axios.jsonp(url).then(response => {
      this.loading = false;
      if (this.more) {
        console.warn("DENTRO DA API");
        let concatArray = this.objects.concat(response.data);
        console.log(concatArray);
        this.objects = this._removeDuplicatedIds(concatArray);
        console.log(this.objects);
      } else {
        this.objects = response.data;
      }
      this.more = false;

      // arquitetura nova
      // this.page = response.data.current_page;
      // this.hasMore = response.data.to < response.data.total;

      // arquitetura antiga
      this.hasMore = response.data.length >= this.limit;

      if (this._isFunction(callback)) {
        callback(this.objects);
      }
    });
  }

  getAll(callback) {
    console.log("BluApi.getAll(callback)");
    this.limit = 99999;
    this.page = 1;
    this.offset = 0;
    this.get(callback);
  }

  getById(id, callback) {
    console.log("BluApi.getById(id, callback)", id);
    this.loading = true;
    this.axios.jsonp(this._getUrl(id)).then(response => {
      this.loading = false;
      const { data } = response;
      callback(data);
    });
  }

  getMore(callback) {
    console.log("BluApi.getMore(callback)");
    // para diferenciar clique no botão mostrar mais ou inicialização da página
    this.more = true;

    // arquitura nova
    this.page++;

    // arquitetura antiga
    this.offset += this.limit;
    this.get(callback);
  }

  order(column, callback) {
    console.log("BluApi.order(column, callback)", column);
    this._orderBy === column
      ? (this._orderDesc = !this._orderDesc)
      : (this._orderBy = column);
    this.page = 1;
    this.get(callback);
  }

  addKey(keyName, keyValue, callback, autoReload = true) {
    console.log(
      "BluApi.addKey(keyName, keyValue, callback, autoReload)",
      keyName,
      keyValue
    );
    if (this.hasKey(keyName)) {
      this.removeKey(keyName, null, false);
    }
    this.keys.push({ name: keyName, value: keyValue });
    this.page = 1;
    this.offset = 0;
    if (autoReload) {
      this.get(callback);
    }
  }

  hasKey(keyName) {
    var has = false;
    this.keys.forEach(function(key) {
      if (key.name === keyName) {
        has = true;
      }
    });
    return has;
  }

  getKey(keyName) {
    var value = false;
    this.keys.forEach(function(key) {
      if (key.name === keyName) {
        value = key.value;
      }
    });
    return value;
  }

  removeKey(keyName, callback, autoReload = true) {
    console.log("BluApi.removeKey(keyName)", keyName);
    this.keys.forEach(function(key, index, object) {
      if (key.name === keyName) {
        object.splice(index, 1);
      }
    });
    if (autoReload) {
      this.get(callback);
    }
  }

  _getUrl(id = false) {
    let url = "";
    if (id) {
      url = this.baseUrl + "id/" + id;
    } else {
      url =
        this.baseUrl +
        "?page=" +
        this.page +
        "&q=" +
        this.filter +
        "&order=" +
        this._orderBy +
        "&desc=" +
        this._orderDesc +
        "&offset=" +
        this.offset +
        "&limit=" +
        this.limit +
        "&show=" +
        this.show;

      this.keys.forEach(function(key) {
        url += "&" + key.name + "=" + key.value;
      });
    }

    console.log("ApiBlu._getUrl(id)", id, url);
    return url;
  }

  _removeDuplicatedIds(myArr) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj["id"]).indexOf(obj["id"]) === pos;
    });
  }

  _isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  }
}
