import React from "react";
import { useSelector } from "react-redux";
import { Wrapper, Botao } from "./style";
import Typography from "@material-ui/core/Typography";
import postCtrl from "../../../../../controllers/post";

export default function Buttons() {
  const levels = useSelector(s => s.levels);
  const level1 = useSelector(s => s.level1);

  function setLevel(l1) {
    if (level1 && l1.id === level1.id) {
      postCtrl.setLevel1();
    } else {
      postCtrl.setLevel1(l1);
    }
  }

  return (
    <Wrapper>
      {levels.map(l1 => {
        return (
          <div key={l1.id}>
            {(!level1 || level1.id === l1.id) && (
              <Botao
                onClick={() => setLevel(l1)}
                key={l1.id}
                style={{ textAlign: "left" }}
              >
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  style={{ fontSize: "15.2px", fontWeight: "bold" }}
                >
                  {l1.name}
                </Typography>
              </Botao>
            )}
          </div>
        );
      })}
    </Wrapper>
  );
}
