import styled from "styled-components";
import bgimg from "../../assets/img/bg1.png";

export const Wrapper = styled.section`
  position: relative;
  background: #e5ddd5;
`;

export const Screen = styled.section`
  background-image: url(${bgimg});
  color: #303030;
  min-height: 100vh;
`;

export const InnerWrapper = styled.section`
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
`;

export const Sociais = styled.section`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
  > * {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
`;

