import React from "react";
import { Wrapper, BgBlack } from "./style";
import Header from "./Header";
import Footer from "./Footer";
import Level1 from "./Level1";
import Level2 from "./Level2";
import Level3 from "./Level3";
import { useSelector } from "react-redux";

export default function PostMenu() {
  const level1 = useSelector(s => s.level1);
  const level2 = useSelector(s => s.level2);

  return (
    <Wrapper>
      <Header />
      <BgBlack>
        <Level1 />
      </BgBlack>
      {level1 && (!level2 || !level2.hide) && <Level2 />}
      {level2 && <Level3 />}
      <Footer />
    </Wrapper>
  );
}
