import React from "react";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Grid from "@material-ui/core/Grid";
import postCtrl from "../../../controllers/post";
import InputMask from "react-input-mask";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function(props) {
  // const [post, setPost] = useState(srvNewPost.get());
  const newPost = useSelector(s => s.newPost);
  const newPostErrors = useSelector(s => s.newPostErrors);

  const hasError = name => {
    return newPostErrors.find(e => e.key === name) ? true : false;
  };

  const handleChange = field => e => {
    newPost.seller[field] = e.target.value;
    postCtrl.setNewPost(newPost);
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" direction="column">
      <Grid item>
        <TextField
          error={hasError("sellerName")}
          variant="filled"
          label="Nome completo"
          defaultValue={newPost.seller.name}
          onChange={handleChange("name")}
          style={{ width: "265px" }}
          inputProps={{
            maxLength: 31
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item>
        <InputMask
          mask="(99) 9 9999-9999"
          maskChar=" "
          onChange={handleChange("phone")}
          defaultValue={newPost.seller.phone}
        >
          {() => (
            <TextField
              error={hasError("sellerPhone")}
              variant="filled"
              component={"span"}
              label="Número Whatsapp"
              style={{ width: "265px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WhatsApp />
                  </InputAdornment>
                )
              }}
            ></TextField>
          )}
        </InputMask>
      </Grid>
    </Grid>
  );
}
