import styled from "styled-components";

export const Wrapper = styled.section`
  /* border: 1px solid black; */
  width: 190px;
  padding: 5px;
`;

export const BgBlack = styled.section`
  background: #303030;
  padding: 5px 0;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
