import store from "../store";

class NavigationController {
  pushWithScroll(scroll, callback) {
    store.dispatch({
      type: "SET_NAVIGATION_SCROLL",
      navigationScroll: scroll
    });
    callback();
  }

  goBackWithScroll(callback) {
    callback();
  }

}

const navCtrl = new NavigationController();

export default navCtrl;
