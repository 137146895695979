import React from "react";
import { Wrapper } from "./style";
import Button from "./Button";
import Modal from "./Modal";

export default function() {
  return (
    <Wrapper>
      <Button />
      <Modal />
    </Wrapper>
  );
}
