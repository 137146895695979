import React from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Wrapper } from "./style";
import postCtrl from "../../../../../controllers/post";

export default function Buttons() {
  const level1 = useSelector(s => s.level1);
  const level2 = useSelector(s => s.level2);

  function setLevel(l2) {
    postCtrl.setLevel2(l2);
  }

  function getVariant(l2) {
    if (!level2 || l2.id === level2.id) {
      return "contained";
    }
    return "outlined";
  }

  return (
    <Wrapper>
      <ButtonGroup
        orientation="vertical"
        color="primary"
        variant="outlined"
        style={{ width: "100%" }}
      >
        {level1 &&
          level1.childs.map(l2 => {
            return (
              <Button
                variant={getVariant(l2)}
                onClick={() => setLevel(l2)}
                key={l2.id}
              >
                {l2.name}
              </Button>
            );
          })}
      </ButtonGroup>
    </Wrapper>
  );
}
